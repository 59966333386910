<template>
    <div class="bg-page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('钱包地址绑定')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="bind-panel">
            <div class="usdt-first">
                <div class="cur-card">
                    {{$t('USDT')}}
                </div>
            </div>
            <div class="usdt-panel">
                <div class="bs-panel_top">{{active}}</div>
                <van-form class="bs-form">
                    <van-field v-model="usdt_address" :placeholder="$t('请输入钱包地址')">
                    </van-field>
                    <!-- <div style="word-break: break-all;padding: 10px 20px;text-align: left;line-height: 20px;font-size: 12px">
                        {{usdt_address}}
                    </div> -->
                    <div class="text-gray text-tip">{{$t('钱包地址提现绑定说明')}}</div>
                </van-form>
            </div>
        </div>

        <div class="btn-space btn-fixed">
            <van-button color="linear-gradient(to right, #3270e0, #3653ea)"  @click="submit" block round>{{$t('确认')}}</van-button>
        </div>
    </div>
</template>

<script>
    import helper from "../../utils/helper";

    export default {
        components: {},
        data() {
            return {
                id: 0,
                active: 'TRC20',
                tkvalue: 'USDT',
                value: '',
                radio: '1',
                list: [
                    'TRC20',
                    'ERC20',
                ],
                usdt_address: '',
            }
        },
        methods: {
            changeTab() {
                if (this.id == 0) {
                    this.usdt_address = '';
                }

            },
            submit() {
                this.loading = true;
                let loading = this.$toast.loading();
                let url = '/bank/save_usdt';
                let _data = {
                    id: this.id,
                    type: 1,
                    bank_name: this.active,
                    number: this.usdt_address,
                };
                let data = helper.encrypt(JSON.stringify(_data));
                this.$axios
                    .post(
                        url,
                        this.$qs.stringify({
                            data: data,
                        })
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$toast.success(res.data.msg);
                            this.$router.push({path: '/bank'})
                        } else {
                            this.$toast.fail({duration: 3000, message: res.data.msg});
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        // loading.clear();
                        this.loading = false;
                    });
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?id=' + this.id).then((res) => {
                    let data = res.data;
                    if (data.code == 0) {
                        let type = data.data.info.type ?? 0;
                        if (type == 1) {
                            this.active = data.data.info.bank_name;
                            console.log(this.active);
                            this.usdt_address = data.data.info.number;
                        }
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            onClickLeft() {
                this.$router.go(-1)
            },
        },
        created() {
            if (this.$route.query.id !== undefined) {
                this.id = this.$route.query.id;
            }
            if (this.$route.query.channel !== undefined) {
                this.active = this.$route.query.channel;
            }
            this.getData();
        }

    }
</script>
<style>
    .cur-card{
        background-color: #2c384a;
        border-radius: 10px;
        padding: 15px;
        font-size: 20px;
        font-weight: bold;
    }
    .usdt-panel{
        background-color: #252f46;
        border-radius: 10px;
        padding: 15px;
        font-size: 20px;
    }
    .bs-panel_top{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .text-tip{
        font-size: 14px;
    }




</style>

<style scoped>
    .usdt-first{
        margin-bottom: 20px;
    }
    .bg-page ::v-deep .van-nav-bar{
        background-color: #1a2331;
    }
    .bind-panel{
        background-color: #1a2331;
        padding: 20px;
        border-radius:  0 0 30px 30px;
    }
    .btn-space{
        padding: 30px;
    }
    .bind-panel ::v-deep .van-cell{
        display: block;
        padding: 0;
        background-color: transparent;
    }
    .bind-panel ::v-deep .van-field__label{
        margin-bottom: 6px;
    }
    .bind-panel ::v-deep .van-cell__value{
        background-color: #2c384a;
        border-radius: 12px;
        padding: 12px 15px;
    }
    .bind-panel ::v-deep .van-cell::after{
        display: none;
    }

    .bind-panel ::v-deep .van-field__control{
        color: #ffffff;
    }







</style>